import { CmsConfigRendererProps } from "@/components/cms/cmsConfigModal/cmsConfigRenderer";
import PbInput from "@/components/input/pbInput/pbInput";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfigModalTabs from "@/hooks/useConfigModalTabs";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import { useAppSelector } from "@/store/store";
import {
  CEImageWithMarkers,
  CEImageWithMarkersItem,
} from "@/types/content-elements";
import { StrapiUploadFile } from "@/types/strapi";
import { deepImmutableCopy } from "@/utils/util";
import { useState } from "react";
import { useDispatch } from "react-redux";
import CmsContentImage from "../cmsContent/cmsContentImage";
import GenericConfigTabExtender from "./genericConfigTabExtender";
import GenericConfigTabs from "./genericConfigTabs";

interface CmsGenericConfigProps extends CmsConfigRendererProps {
  currentElement: CEImageWithMarkers;
}

export default function CmsImageWithMarkersConfig(
  props: CmsGenericConfigProps
) {
  const dispatch = useDispatch();
  const sideMenu = useAppSelector(
    (state) => state.cmsUser.cmsUserSettings?.sideMenu
  );
  const tCms = useCmsTranslation();
  const { configModalTabs, filterConfigModalFieldsFromConfigModalTabs } =
    useConfigModalTabs(props.attributes, props.component);

  // filter elements that you want to render with custom logic
  const filteredConfigModalTabs = filterConfigModalFieldsFromConfigModalTabs(
    deepImmutableCopy(configModalTabs),
    ["cfgImg", "cfgImageWidth", "cfgImageHeight"]
  );

  const [widthWarningText, setWidthWarningText] = useState("");

  const WIDTH_DEFAULT = 800;
  const HEIGHT_DEFAULT = 600;
  const MIN_IMG_WIDTH_HEIGHT = 100;
  const MAX_DEFAULT_WIDTH_HEIGHT_TEXTBOX = 300;

  const calculateHeight = (
    originalWidth: number,
    originalHeight: number,
    newWidth: number
  ): number => {
    if (newWidth === 0) {
      return MIN_IMG_WIDTH_HEIGHT;
    }

    if (!originalWidth || !originalHeight || !newWidth) {
      return HEIGHT_DEFAULT;
    }
    const aspectRatio = originalHeight / originalWidth;
    const newHeight = newWidth * aspectRatio;
    return Math.floor(newHeight);
  };

  const onWidthChange = (value: string) => {
    if (value) {
      const inputValue = Math.round(parseInt(value, 10));
      const containerPercentage = globalConfig?.layout.containerWidth
        ? parseInt(globalConfig.layout.containerWidth, 10)
        : MIN_IMG_WIDTH_HEIGHT;
      const containerWidth =
        (document.getElementById("layout")!.clientWidth /
          MIN_IMG_WIDTH_HEIGHT) *
        containerPercentage;
      let width = inputValue <= containerWidth ? inputValue : containerWidth;
      width = width < MIN_IMG_WIDTH_HEIGHT ? MIN_IMG_WIDTH_HEIGHT : width;
      props.onChange("cfgImageWidth", Math.round(width));

      const height = updateImgHeight(width);
      resetItemPositions(width, height);
    }
  };

  const updateImgHeight = (correctedWidth: number) => {
    let height = HEIGHT_DEFAULT;
    if (props.currentElement.cfgImg) {
      const newHeight = calculateHeight(
        props.currentElement.cfgImg.width ?? WIDTH_DEFAULT,
        props.currentElement.cfgImg.height ?? HEIGHT_DEFAULT,
        correctedWidth
      );
      height = Math.max(newHeight, MIN_IMG_WIDTH_HEIGHT);
    }
    props.onChange("cfgImageHeight", Math.max(height, MIN_IMG_WIDTH_HEIGHT));
    return height;
  };

  const resetItemPositions = (imgWidth: number, imgHeight: number) => {
    const items: Array<CEImageWithMarkersItem> = props.currentElement.content;
    items.forEach((item, index) => {
      const toolbarHeight = 42;

      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.currentElement.positionCE}].content[${index}].position`,
          value: { x: 0, y: 0 },
        })
      );

      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.currentElement.positionCE}].content[${index}].markerPosition`,
          value: { x: 0, y: 0 },
        })
      );

      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.currentElement.positionCE}].content[${index}].height`,
          value: Math.round(
            Math.min(
              Math.max(imgHeight / 2 - toolbarHeight, 50),
              MAX_DEFAULT_WIDTH_HEIGHT_TEXTBOX
            )
          ),
        })
      );

      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.currentElement.positionCE}].content[${index}].width`,
          value: Math.round(
            Math.min(
              Math.max(imgWidth / 2, MIN_IMG_WIDTH_HEIGHT),
              MAX_DEFAULT_WIDTH_HEIGHT_TEXTBOX
            )
          ),
        })
      );
    });
  };

  return (
    <GenericConfigTabs
      configModalTabs={filteredConfigModalTabs}
      onChange={props.onChange}
    >
      <GenericConfigTabExtender tabName="options" index={1}>
        <ModalInputWrapper
          label={tCms("configModal-cfgImageWidth")}
          description={tCms("configModal-cfgImageWidthDescription")}
          fullWidth={sideMenu}
          hiddenDescription={sideMenu}
          showTooltip={sideMenu}
          warningText={widthWarningText}
        >
          <PbInput
            type="number"
            fullWidth
            defaultValue={props.currentElement.cfgImageWidth}
            onFocus={() => {
              setWidthWarningText(tCms("imgWithMarkers-widthWarning"));
            }}
            onBlur={() => {
              setWidthWarningText("");
            }}
            onChange={(e) => {
              onWidthChange(e.target.value);
            }}
          />
        </ModalInputWrapper>
        <ModalInputWrapper
          label={tCms("configModal-cfgImg")}
          description={tCms("configModal-cfgImgDescription")}
          fullWidth={sideMenu}
          hiddenDescription={sideMenu}
          showTooltip={sideMenu}
        >
          <CmsContentImage
            isMobile={false}
            img={props.currentElement.cfgImg}
            widthLimitSettings={150}
            onChange={(img?: StrapiUploadFile) => {
              if (!img) {
                // img was removed
                props.onChange("cfgImg", null);
                // reset all marker and text positions
                if (props.currentElement.content) {
                  props.currentElement.content.forEach(
                    (element: any, index: number) => {
                      props.onChange(`content[${index}].markerPosition`, {
                        x: 1,
                        y: 1,
                      });
                      props.onChange(`content[${index}].position`, {
                        x: 1,
                        y: 1,
                      });
                    }
                  );
                }
              } else {
                // image selected
                const newHeight = calculateHeight(
                  img.width ?? WIDTH_DEFAULT,
                  img.height ?? HEIGHT_DEFAULT,
                  props.currentElement.cfgImageWidth ?? WIDTH_DEFAULT
                );
                props.onChange(
                  "cfgImageWidth",
                  props.currentElement.cfgImageWidth ?? WIDTH_DEFAULT
                );
                props.onChange("cfgImageHeight", newHeight);
                props.onChange("cfgImg", img);
              }
            }}
          />
        </ModalInputWrapper>
      </GenericConfigTabExtender>
    </GenericConfigTabs>
  );
}
