import { ConfigModalEntry } from "@/components/cms/cmsConfigModal/cmsConfigModal";
import PbDropdown, {
  PbDropdownItem,
} from "@/components/input/pbDropdown/pbDropdown";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import {
  CFG_CONTENT_ELEMENT_SETTING_NAME,
  checkPersistStoreSettingValues,
  filteredCeSettingsByStoreAndPageType,
  storeSettingByField,
} from "@/services/ceSettings/ceSettingsService";
import { useAppSelector } from "@/store/store";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { PbPageType } from "@/types/strapi";
import { DEFAULT_AS_SELECTABLE_VALUE } from "@/utils/constants";
import { isStoreValueDefaultAllowed } from "@/utils/util";
import { SelectChangeEvent } from "@mui/material";

interface StoreConfigInputProps {
  configEntry: ConfigModalEntry;
  onChange: (attributeName: string, event: any) => void;
  label: string;
  description: string;
}
export default function GenericStoreConfigInputRenderer(
  props: StoreConfigInputProps
) {
  const tCms = useCmsTranslation();
  const contentElementSetting = useAppSelector(
    (state) => state.cmsGeneral.configModal.contentElementSetting
  );
  const sideMenu = useAppSelector(
    (state) => state.cmsUser.cmsUserSettings?.sideMenu
  );
  const pageType: PbPageType = useAppSelector(
    (state) => state.cmsEdit.data.draftPage.type
  );

  const storeSettings =
    (props.configEntry.value as StoreSetting) ??
    (props.configEntry.attributes?.default as StoreSetting);
  if (!storeSettings) {
    return <>No StoreSettings found!</>;
  }

  const availableValues: StoreSetting | undefined = storeSettingByField(
    contentElementSetting,
    storeSettings.settingsField
  );

  const contentElementDropdownItems = () => {
    return filteredCeSettingsByStoreAndPageType(
      storeSettings.storeType,
      pageType ? pageType.id : null,
      null
    ).map(
      (setting): PbDropdownItem => ({
        value: setting.id.toString(),
        name: setting.setting.strname,
      })
    );
  };

  const getDefaultValue = () => {
    if (storeSettings.values.length === 0) {
      return undefined;
    }
    if (
      !checkPersistStoreSettingValues(storeSettings.values, availableValues)
    ) {
      props.onChange(props.configEntry.name, {
        ...storeSettings,
        values: [],
      } as StoreSetting);
      return undefined;
    }
    if (isStoreValueDefaultAllowed(storeSettings.values)) {
      return -1 + "";
    }
    return storeSettings.values.at(0) + "";
  };

  const storeSettingDropDownItems = () => {
    return availableValues?.data
      ? Object.entries(availableValues.data)
          .map(([key, value]): PbDropdownItem | undefined => {
            if (key === `${DEFAULT_AS_SELECTABLE_VALUE}`) {
              return {
                value: key,
                name: tCms("configModal-defaultValue"),
              };
            }
            if (!value) {
              return undefined;
            }
            if (value.valueAttribute === "color") {
              return {
                value: key,
                name: value.strname,
                iconName: "circle-solid",
                iconStyle: { fill: value.color },
              };
            }
            return {
              value: key,
              name: value.strname,
            };
          })
          .filter((item) => item !== undefined)
          .map((item) => item as PbDropdownItem)
      : [];
  };

  const changeHandler = (event: SelectChangeEvent) => {
    const selectedValue = parseInt(event.target.value);
    props.onChange(props.configEntry.name, {
      ...storeSettings,
      values: selectedValue ? [selectedValue] : [],
    } as StoreSetting);
  };

  const layoutDropdownItems = (): PbDropdownItem[] => {
    if (
      !contentElementSetting?.setting ||
      !contentElementSetting.setting["layouts"]
    ) {
      return [];
    }
    const availableLayouts: string = contentElementSetting.setting["layouts"];
    return availableLayouts.split(",").map((layout) => ({
      name: tCms("configModal-gallery-layout-" + layout),
      value: layout,
    }));
  };

  const layoutChangeHandler = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    props.onChange(props.configEntry.name, {
      ...storeSettings,
      values: [selectedValue],
    });
  };

  const hasEnoughEntries = (
    options: Array<PbDropdownItem>,
    minLength: number = 1
  ) => {
    return options?.length >= minLength;
  };

  const renderStoreConfigInputElement = (configEntry: ConfigModalEntry) => {
    switch (configEntry.name) {
      case "cfgStrLayout":
        const layoutOptions = layoutDropdownItems();

        if (!hasEnoughEntries(layoutOptions)) {
          return null;
        }

        return (
          <PbDropdown
            dropdownList={layoutOptions}
            onChange={layoutChangeHandler}
            defaultValue={
              storeSettings.values.length > 0
                ? storeSettings.values.at(0) + ""
                : "default"
            }
            itemNameKey="name"
            itemValueKey="value"
          ></PbDropdown>
        );
      case CFG_CONTENT_ELEMENT_SETTING_NAME:
        const contentElementSettingOptions = contentElementDropdownItems();

        if (!hasEnoughEntries(contentElementSettingOptions, 2)) {
          return null;
        }

        return (
          <PbDropdown
            dropdownList={contentElementSettingOptions}
            onChange={changeHandler}
            defaultValue={
              storeSettings.values.length > 0
                ? storeSettings.values.at(0) + ""
                : undefined
            }
            itemNameKey="name"
            itemValueKey="value"
          ></PbDropdown>
        );
      case "cfgStrBackground":
        // cfgStrBackground uses default behaviour with an additional str-background-preview-div.
        const backgroundSettingOptions = storeSettingDropDownItems();

        if (!hasEnoughEntries(backgroundSettingOptions, 2)) {
          return null;
        }
        return (
          <>
            <PbDropdown
              dropdownList={backgroundSettingOptions}
              onChange={changeHandler}
              defaultValue={getDefaultValue()}
              itemNameKey="name"
              itemValueKey="value"
            ></PbDropdown>
            {configEntry.name === "cfgStrBackground" &&
            storeSettings.values[0] &&
            storeSettings.values[0] !== DEFAULT_AS_SELECTABLE_VALUE ? (
              <div
                className={`str-background-${storeSettings.values[0]}-preview`}
                style={{
                  marginTop: "1rem",
                  height: "200px",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              ></div>
            ) : null}
          </>
        );
      default:
        const storeSettingOptions = storeSettingDropDownItems();

        if (!hasEnoughEntries(storeSettingOptions, 2)) {
          return null;
        }
        return (
          <>
            <PbDropdown
              dropdownList={storeSettingOptions}
              onChange={changeHandler}
              defaultValue={getDefaultValue()}
              itemNameKey="name"
              itemValueKey="value"
            ></PbDropdown>
          </>
        );
    }
  };

  const storeConfigInputElement = renderStoreConfigInputElement(
    props.configEntry
  );
  if (storeConfigInputElement) {
    return (
      <ModalInputWrapper
        fullWidth={sideMenu}
        hiddenDescription={sideMenu}
        showTooltip={sideMenu}
        label={props.label}
        description={props.description}
      >
        {storeConfigInputElement}
      </ModalInputWrapper>
    );
  } else {
    return <></>;
  }
}
